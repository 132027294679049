import React from "react";
import { ReactNode } from "react";

export interface Props {
  title: string;
  body: ReactNode;
}

export const LoginCardInternal = ({ title, body }: Props) => {
  return (
    <div className="m-auto">
      <div className="card w-96 shadow-xl  bg-white">
        <h2 className="card-title items-center text-center justify-center my-4">
          {title}
        </h2>
        <div className="card-body border-t-2">{body}</div>
      </div>
    </div>
  );
};

export const LoginCard = ({ title, body }: Props) => {
  return (
    <div className="flex h-screen w-screen">
      <LoginCardInternal title={title} body={body} />
    </div>
  );
};

export default LoginCard;
