import { setCookie, deleteCookie, getCookie } from "cookies-next";

import { OptionsType } from "cookies-next/lib/types";
export const API_URL = process.env.NEXT_PUBLIC_API_URL;

export const COOKIE_NAME = "token";
export const REFRESH_COOKIE_NAME = "refresh";

const storeTokens = (responseJSON: TokenResponse, context?: OptionsType) => {
  setCookie(COOKIE_NAME, responseJSON.accessToken, {
    ...context,
    sameSite: "none",
    secure: true,
  });
  setCookie(REFRESH_COOKIE_NAME, responseJSON.refreshToken, {
    ...context,
    sameSite: "none",
    secure: true,
  });
};

type SignInProps = {
  //   redirect?: string;
  email: string;
  password: string;
};
export async function signIn({ email, password }: SignInProps) {
  try {
    const res = await fetch(`${API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseJSON = await res.json();
    if (!res.ok) {
      throw new Error(responseJSON.message);
    }

    //   // If no error and we have user data, return it
    if (res.ok && responseJSON) {
      storeTokens(responseJSON);
      return true;
    } else {
      throw "not a good response from API";
    }
  } catch (error) {
    console.log("error", error);
    return false;
  }
}

export async function signOut(context?: OptionsType) {
  deleteCookie(COOKIE_NAME, context); // - server side
  deleteCookie(REFRESH_COOKIE_NAME, context); // - server side
  return true;
}

type TokenResponse = {
  accessToken: string;
  refreshToken: string;
};

export async function refresh(context?: OptionsType) {
  //
  const rToken = await getRefreshToken(context);
  console.log("refreshing", rToken);

  if (rToken) {
    //do the work
    const newStuff = await fetch(`${API_URL}/auth/refresh`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ refreshToken: rToken }),
    });

    const json = (await newStuff.json()) as TokenResponse;

    // console.log("newToken", json);

    //todo check its ok
    if (newStuff.status === 200) {
      storeTokens(json, context);
    }

    return json.accessToken;
  }

  return null;
}

export async function getCsrfToken(
  context?: OptionsType | undefined,
): Promise<string | null> {
  const foundCookie = getCookie(COOKIE_NAME, context);

  if (foundCookie && typeof foundCookie === "string") {
    return foundCookie;
  }
  return null;
}

async function getRefreshToken(
  context?: OptionsType | undefined,
): Promise<string | null> {
  const foundCookie = getCookie(REFRESH_COOKIE_NAME, context);

  if (foundCookie && typeof foundCookie === "string") {
    return foundCookie;
  }
  return null;
}

export async function me() {
  //
}
