import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { signIn, signOut } from "@/lib/Auth";
import { useRouter } from "next/router";
import clsx from "clsx";

import LoginCard from "@/components/LoginCard";
import Link from "next/link";

export default function Login() {
  const router = useRouter();

  type FormValues = {
    email: string;
    password: string;
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({});

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const subscription = watch(() => setError(""));
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data: { email: string; password: string }) => {
    setIsLoading(true);
    await signOut(undefined);
    const ok = await signIn({ email: data.email, password: data.password });
    if (ok) {
      //redirect to /admin
      if (router.query?.redirect?.toString()?.length) {
        router.push(router.query?.redirect?.toString());
      } else {
        router.push("/admin");
      }
    } else {
      //show error?
      setIsLoading(false);
      setError(
        "Unable to log in, please check your username and password are correct.",
      );
    }
  };

  return (
    <LoginCard
      title="Login"
      body={
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="form-control w-full max-w-xs">
            <input
              placeholder="Email address"
              className="input input-bordered w-full max-w-xs"
              type="email"
              id="email"
              // value="martin@pikl.com"
              {...register("email", { required: true })}
            />
            {errors?.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
          </div>
          <div className="form-control w-full max-w-xs">
            <input
              placeholder="Password"
              className="input input-bordered w-full max-w-xs"
              type="password"
              id="password"
              // value="flump"
              {...register("password", { required: true })}
            />
            {errors?.password && (
              <p className="text-red-500">{errors.password.message}</p>
            )}
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <div className="mt-4">
            <button
              type="submit"
              className={clsx(
                "btn btn-primary w-full",
                isLoading && "btn-disabled",
              )}
            >
              Sign in with Email
            </button>
          </div>
          {!isLoading && (
            <Link href="/forgot" className="btn btn-link">
              Forgot your password?
            </Link>

            // <div className="text-center text-blue-600 dark:text-blue-500 hover:underline">
            //   <Link href="/forgot">Forgot your password?</Link>
            // </div>
          )}
        </form>
      }
    />
  );
}
